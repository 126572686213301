<template>
  <v-theme-provider light>
    <v-form ref="form">
      <v-overlay :value="isPageLoading">
        <v-progress-circular indeterminate size="64"> </v-progress-circular>
      </v-overlay>
      <div>
        <BannerAccepted v-if="isAcceptedProp && !isPageLoading" :timeout="infinityTimeoutProp" :value="true" shaped color="primary" />
      </div>
      <base-section class="text-center" space="86">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="12">
            <v-snackbar v-model="showGenericFailure" :timeout="infinityTimeoutProp">
              <v-container fluid ma-0 pa-0 ml-4 fill-width style="width: 100%">
                <span class="centered">
                  <span>That's unexpected! {{ genericFailureErrorMessage }}</span>
                  <v-spacer />
                  <v-btn
                    @click="
                      showGenericFailure = false
                      isPageLoading = false
                    "
                    text
                    style="display: flex; justify-content: flex-end"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </span>
              </v-container>
            </v-snackbar>
          </v-col>
        </v-row>
        <base-section-heading title="Active Specialists" color="primary"><v-icon left>mdi-web</v-icon>{{ networktitleProp }}</base-section-heading>
        <v-responsive class="d-flex align-center" height="100%" width="100%">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12">
                <v-card>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" clearable label="Filter by" single-line hide-details></v-text-field>
                  </v-card-title>
                  <v-data-table :loading="isTableLoading" :headers="headers" :items="professionalsList" :items-per-page="-1" :search="search" class="elevation-1 row-pointer" @click:row="handleRowClick">
                    <template v-slot:item.sales="{ item }">
                      <v-chip :color="getSalesColor(item.sales)" dark>
                        {{ item.sales }}
                      </v-chip>
                    </template>

                    <template v-slot:item.prepopulated="{ item }">
                      <v-icon v-if="!item.prepopulated" right color="primary"> mdi-check-decagram</v-icon>
                    </template>

                    <template v-slot:item.zip="{ item }">
                      <v-chip :color="getZipColor()" label dark>
                        {{ item.zip }}
                      </v-chip>
                    </template>

                    <template v-slot:no-results>
                      <v-alert :value="true" type="info">We could not find anything with "{{ search }}" in your network. </v-alert>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <v-footer :fixed="true">
              <v-row justify="center" align="center" class="footer">
                <v-col cols="12" sm="12">
                  <v-snackbar loading v-model="showSavedProgress" :timeout="longTimeoutProp">
                    Searching…
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                  </v-snackbar>
                </v-col>
              </v-row>
              <v-row justify="center" align="center" class="footer">
                <v-col cols="12" sm="12">
                  <v-snackbar v-model="showSavedFailure" :timeout="infinityTimeoutProp" color="error">
                    <v-container fluid ma-0 pa-0 ml-4 fill-width style="width: 100%">
                      <span class="centered">
                        <span>Error while searching: {{ savedFailureErrorMessage }}</span>
                        <v-spacer />
                        <v-btn @click="showSavedFailure = false" text style="display: flex; justify-content: flex-end">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </span>
                    </v-container>
                  </v-snackbar>
                </v-col>
              </v-row>
            </v-footer>
          </v-container>
        </v-responsive>
      </base-section>
    </v-form>
  </v-theme-provider>
</template>

<script>
import { AuthServiceConstants } from '@/msal/AuthServiceInterface'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { RegistrationStage, AlertTimeout } from '@/RegistrationStage.ts'
import { RemoteAccess } from '@/RemoteAccess'

export default {
  components: {
    BannerAccepted: () => import('./BannerAccepted.vue')
  },

  name: 'NetworkSection',
  data: () => ({
    isPageLoading: true,
    isTableLoading: false,
    professionalsList: [],
    networkname: '',
    search: '',

    showSavedFailure: false,
    showSavedProgress: false,
    showSavedSuccess: false,
    savedFailureErrorMessage: '',
    showGenericFailure: false,
    genericFailureErrorMessage: '',

    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'fullname'
      },
      {
        text: 'Annual Clients Served',
        value: 'sales'
      },
      {
        text: 'Profession',
        value: 'profession'
      },
      {
        text: 'Zip',
        value: 'zip'
      },
      {
        text: 'City',
        value: 'city'
      },
      {
        text: 'State',
        value: 'state'
      },
      {
        text: 'Certified',
        value: 'prepopulated'
      }
    ],
    registrationStage: RegistrationStage.Disabled
  }),

  mounted() {
    setTimeout(this.fetchRegistrationStageThenNetworkList, this.nullTimeoutProp)
  },

  computed: {
    nullTimeoutProp() {
      return AlertTimeout.null
    },

    longTimeoutProp() {
      return AlertTimeout.long
    },

    infinityTimeoutProp() {
      return AlertTimeout.infinity
    },
    networktitleProp() {
      return `Certified Community Specialists in ${this.networkname}`
    },

    isAcceptedProp() {
      return this.registrationStage === RegistrationStage.Accepted
    }
  },

  methods: {
    handleRowClick(person) {
      this.$router.push({
        name: 'profilereadonlyroute',
        params: {
          person
        }
      })
    },

    fetchRegistrationStageThenNetworkList() {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🤲 MyNetworkSection.fetchRegistrationStageThenNetworkList', // log hint
        endpoints.myprofileEndpoint, // endpoint
        environmentVariables.MY_PROFILE_KEY, // code
        {}, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.genericFailureErrorMessage = ''
          this.isPageLoading = true
          this.showGenericFailure = false

          return true
        },

        // onSuccess
        (data) => {
          this.isPageLoading = false
          this.networkname = data.profile.networkname
          this.registrationStage = data.profile.registrationstage
          this.fetchNetworkMembersList()
        },

        // All sort of errors
        (error, errorDescription) => {
          this.isPageLoading = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            this.showGenericFailure = true
            this.genericFailureErrorMessage = errorDescription
          }
        }
      )
    },

    fetchNetworkMembersList() {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '👣 MyNetworkSection.fetchNetworkMembersList', // log hint
        endpoints.myNetworkEndpoint, // endpoint
        environmentVariables.MY_NETWORK_KEY, // code
        {}, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.genericFailureErrorMessage = ''
          this.isTableLoading = true
          this.professionalsList = []
          this.showGenericFailure = false
          this.showHeader = false
          this.showSavedFailure = false
          this.showSavedProgress = true

          return true
        },

        // onSuccess
        (data) => {
          this.formisdirty = false
          this.isTableLoading = false
          this.professionalsList = data.list
          this.showHeader = true
          this.showSavedProgress = false
        },

        // All sort of errors
        (error, errorDescription) => {
          this.isTableLoading = false
          this.showSavedProgress = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            this.showGenericFailure = true
            this.genericFailureErrorMessage = errorDescription
          }
        }
      )
    },

    getSalesColor(sales) {
      // TODO: to be rewritten!
      if (sales < 15) return '#A3EBB1'
      else if (sales > 15 && sales < 40) return '#21B6A8'
      else return '#7A89C5'
    },

    getZipColor() {
      // to be rwritten
      return 'gray'
    },

    showLoggedOutBanner() {
      // MustLoginSection should already have a modal
    }
  }
}
</script>

<style>
.row-pointer:hover {
  cursor: pointer;
}
</style>
